<template>
<div class='inputWrapper' :style='getStyle()'>
    
    <span v-if='clipboard'>
      
        <span class='span25' :disabled=disabled :style='getAppStyle()'>
		    <input v-if="border" class="inputBorder" :disabled=disabled  :style="getAppStyle()"
		    	@change="changed" 
		    	@keyup.esc="escapePressed()"
		    	@keydown.tab="$emit('tab')"
    	        @blur="focus=false; $emit('input', value); $emit('blur')" 
		    	@keypress.enter="enterPressed()" @focus="focus=true; $event.target.select()" v-model.number="internalValue"/>
		    <input v-else class="inputText" :disabled=disabled  :style='getAppStyle()'
		    	@change="changed" 
		    	@keyup.esc="escapePressed()"
    	        @blur="focus=false; $emit('input', value); $emit('blur')" 
		    	@keypress.enter="enterPressed()" @focus="focus=true; $event.target.select()" v-model.number="internalValue"/>
	    </span>
	    <span class='span75'>
	      <clipboard v-model="internalValue"><icon-write /></clipboard>
	    </span>
		  
    </span>
    <span v-else>
	    <span class='span25NCB'>
	     
	     <input ref='inp' v-if="border" class="inputBorder" :disabled=disabled  :style='getAppStyle()'
	           @keydown.tab="$emit('tab')" @blur="focus=false; $emit('blur')"  @keypress.enter="enterPressed()" @change="changed"  
	           @focus="focus=true; $event.target.select()" v-model="internalValue"/>
	     <input ref='inp' v-else class="inputInt" :disabled=disabled  :style='getAppStyle()'
	           @keydown.tab="$emit('tab')" @blur="focus=false; $emit('blur')"  @keypress.enter="enterPressed()" @change="changed"  
	           @focus="focus=true; $event.target.select()" v-model="internalValue"/>
	    </span>
    </span>
</div>
</template>
<script>
import clipboard from '@/components/Clipboard';
import { getAppStyle, setDarkMode, initAppMode } from '@/AppStyle.js';
var numeral = require('numeral');
export default {
  props: {
    value: Number,
    disabled: Boolean,
    clipboard: { type: Boolean, default: true},
    border: { type: Boolean, default: false}
  },
  data () {
	    return {
	    	focus: false,
	    	getAppStyle
	    }
  },
  components : {
    clipboard
  },
  methods: {
      open() { this.$refs.inp.focus(); this.$refs.inp.select();},
      escapePressed() { this.$emit("escape"); },
      enterPressed() { this.$emit("enter"); },
      changed() { this.$emit("changed", this.value); this.$emit("change", this.value); },
      getStyle() { 
    	  if ( this.disabled )
    	  {
    		  //return 'border: none;';
    	  }
    	  return '';
      }
  },
  mounted() {
    this.open();
  },
  created() {
	  initAppMode();
  },
  computed: {
    internalValue: {
      get() {
        this.$emit("input", this.value )
        if ( this.focus)
        {
        	return this.value;
        }
        return numeral(this.value).format('0,0');
      },
      set(v) {
        this.value = v;
        this.$emit("input", numeral(v).value())
      }
    }
    }
 }
</script>
<style lang="scss" scoped>
input { font-size: 12pt; border: none; width: 100%}
input:disabled { font-size: 12pt; border: none; width: 100%; color: #888 !important; }
input:focus { outline: 1pt dotted grey;}

.inputInt { text-align: right; font-size: 12pt; padding-right: 8pt;}
.inputBorder { font-size: 12pt; padding: 2pt; text-align: right; border: 1pt solid #ccc; width: 80%;  }

.span25 { display: inline-block; width: 85%}
.dist5 { margin-left: 4pt; }
.span75 {  width: 15%; text-align: right; float: right; }
.inputWrapper { 
    border: 1pt solid #ccc;
    border-radius: 6px;
    padding: 2pt;
    width: 100%;
}

.inputButton { 
    border: 1pt solid grey;
}

</style>