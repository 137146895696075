<template>
<div class='inputWrapper'  >
   <div v-if='showOpener' class='input80' >	
         <span v-if="selectData">
		
	         <vSelect :style="getStyle()+'border: 1pt solid #aaa !important; border-radius: 4pt; font-size: 8pt;'" :options="selectData" v-model="selectedValue" :selectable=isSelectable  
	            @close="$emit('close')"
	            :appendToBody="true" :disabled="isDisabled()" @input="setSelected" placeholder=''> 

	        </vSelect>
        </span>
        <span v-else>
        	<input type='text' v-model="selectedValue"/>
        </span>
    </div>	
    <div v-if='showOpener' class='input20' >	
        <span v-tooltip='tooltip()'>
        
		<button :style="getAppStyle()"  :disabled='(! selectedValue.id) || definition.enum || !isSelectable(selectedValue)' class='button btn' @click="openDetail">
		<svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
			  <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
			</svg>
		</button>
		</span>
	 </div>	
	 <div v-else class='input100' >	
         <span v-if="selectData" @mouseover="$event.target.focus();">
			
	         <vSelect :options="selectData" v-model="selectedValue" :selectable=isSelectable :style='getStyle()' :clearable="clearable"
	            @close="$emit('close')"            
	            :appendToBody="true" :disabled="isDisabled()" @input="setSelected" placeholder=''> 
					
					<template #option="{ id, label }" >
	      					<span z-index="999" :style='getStyle()'>{{label}}</span>
	    				 </template>

	        </vSelect>
        </span>
        <span v-else>
        	<input type='text' v-model="selectedValue"/>
        </span>
    </div>	
</div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import {HTTP, fwAPI, accessPointAPI, APIURL} from '@/variables.js';
import { getAppStyle, setDarkMode, initAppMode } from '@/AppStyle.js';
export default {
  name: "GSelect",
  props: {
    disabled: Boolean,
    useCache: { type: Boolean, default: false },
    mark: { type: Boolean, default: false},
    showOpener: { type: Boolean, default: true},
    clearable: { type: Boolean, default: true},
    visible: Boolean,
    data: Object,
    column: String,
    id: Number,
    time: Number,
    entityMap: Object,
    metadata: Object,
    definition: Object,
    value: Number,
    parentMeta: Object,
    parentId: { type: Number, default: 0 },
    fontSize: { type: Number, default: 14 },
  },
  components : { vSelect},
  data() {
  	return {
         linkData: {},
         linkedMetadata: {},
  	   selectData: [],
         selectedValue:  [],
         selectedIndex: 1,
         showEditor: false,
        header: [],
        text: "",
        columns: [],
        limit: 10,
        loadedData: "",
        getAppStyle,
        mapping: [], // row mapping according to visual table
        mappingReverse: [], // id -> rowNo
        row2IdMapping: []  // rowNo -> id
  	}
  },
  methods: {
    searchList(text) { 
    },
    maybeCreate() {},
    openDetail() {
        
    	this.$emit("open", this.selectedValue.id, this.linkData.entity)
    },
    setSelected() {
    	this.$emit("changed", this.selectedValue);
    },
    tooltip() { return ( this.linkData.entity) ? "Open " + this.linkData.entity: {} },
    show() {
    //alert(JSON.stringify(this.definition))
    },
    editor() 
    {
        this.showEditor = true;
    },
    getStyle() { 
      	if ( this.mark )
      	{
      		return "background-color: #fdd;";
      	}
      	return "font-size: " +this.fontSize+"px";
      },
    isSelectable(option) { return option.visible; },
    isDisabled() { return this.disabled || (this.selectedValue.id && !this.selectedValue.visible) },
    closeEditor()
      {
         this.showEditor = false;
         //this.getData();
      },
    update() {},
    doSearch(text) {
    },
    
    getSelectedLink(link)
    {
    	if ( link )
  		{
        	let LinkData = this.metadata.links.find( p => p.name == link);
        }
        return {};
    },
    getSelectedLinkName()
    {
    	let select = this.definition.visual;
  		if ( select )
  		{
  			let scanner = new RegExp("SELECT[(][ ]*([a-zA-Z_0-9]*)[ ]*[)][ ]*");
        	let myArray = scanner.exec(select);
        	// read "link" from SELECT( link )  
        	return myArray[1];
        }
        return null;
    },
    getData()
  	{
  		
		let column = this.definition.column;
		//alert(column + "/"+ this.definition.enum);
        if ( this.definition.enum )
        { 
        	let col = column;
        	
        	if ( !this.entityMap[col])
        	{
	        	if ( column.startsWith("parent") && this.definition.link)
	        	{
	        		// parentType -> link => "xxx.type", so use "link" in parent...
	        		var scanner = new RegExp("([a-zA-Z_0-9]*)[.]([a-zA-Z_0-9]*)");
	        		var myArray = scanner.exec(this.definition.link);
	        		col = myArray[2];
	        	}
	        }
        	this.selectData = JSON.parse( JSON.stringify(this.entityMap[col].data));

        	let enumOrdinal = (! this.value) ? 0 : parseInt(this.value);
        	
        	if ( enumOrdinal >= 0 )
            {
                this.selectedValue = this.selectData.find( p => p.id === enumOrdinal);
				//console.log( JSON.stringify( this.selectedValue ));
            }
            else
            {
                if ( this.selectedValue )
                {
                    this.selectedValue.id = null;
                    this.selectedValue.label = "";
                }
                else
                {
                    this.selectedValue  = {id: null, label:""};
                }
            }
            //if ( column == 'status' )	alert( JSON.stringify(this.selectedValue));
            this.setSelected();
            return;
        }
  		let select = this.definition.visual;
  		
  		scanner = new RegExp("SELECT[(][ ]*([a-zA-Z_0-9]*)[ ]*[)][ ]*");
        myArray = scanner.exec(select);
        
        // read "link" from SELECT( link )  
        var link = myArray[1];
        // find the definition   
        
        let linkData = this.metadata.links.find( p => p.name === link);
        //alert( select + myArray[1]+ linkData.name);
        //console.log( select+ "--> " + linkData + "/"+JSON.stringify(linkData));
        let myValue = this.value;
        let useCache = this.useCache;
        let id = this.id == null?0:this.id;
    	let entity = this.metadata.name;
    	
        if ( !linkData || ! linkData.entity )
        {
        	// no link -> get entity from the given column
        	linkData = this.metadata.columns.find( p => p.column === link);
        	try
        	{
        		// linkdata is now pointing to column
        		let referencedId = parseInt( this.data[linkData.column]);
        		myValue = parseInt( this.value );
	        	useCache = false;
	        	id = myValue;
	        	id = this.id == null?0:this.id;
	        	// find entity 
	        	let mySelect = this.entityMap[linkData.select].data.find( m => m.id == referencedId);
	        	linkData = {entity: mySelect.label, name: mySelect.label };
	        	//alert( JSON.stringify(linkData));
	        	// console.log( select+ "--> " + JSON.stringify(linkData));
	        	//entity = mySelect.label;
        	} catch(e)
        	{
        		console.log( "cant get entity");
        		//this.loadedData = key;
        		this.selectData=0;
        		let col = column;
        		console.log( col);
        		this.selectedValue = this.data[ col];
        		console.log( this.selectedValue);
        		return;
        		
        	}
        }
		
        let key = this.metadata.name+"."+link+"."+this.disabled+"."+((this.value)?this.value:0);
  	    if ( key == this.loadedData && this.selectData && this.selectData.length > 0  )
  	    {
  	    	if ( this.data.id != 0 )
  	    	{
  	    		return;
        	}
        }    
		let useCacheHere = useCache && ! linkData.filter;
        if ( useCacheHere && this.entityMap && this.entityMap[link] )
        {
            this.loadedData = key;
            this.selectData = JSON.parse( JSON.stringify(this.entityMap[link].data));
            if ( this.value  )
            {
                let myId = parseInt(this.value);
                this.selectedValue = this.selectData.find( p => p.id === myId);
            }
            else
            {
                if ( this.selectedValue )
                {
                    this.selectedValue.id = null;
                    this.selectedValue.label = "";
                }
                else
                {
                    this.selectedValue  = {id: null, label:""};
                }
            }
            //console.log( JSON.stringify(this.selectedValue));
            this.setSelected();
            this.linkData = linkData;
            return;
        }
     
  		// linkData = this.metadata.links.find( p => p.entity == link);
        // console.log( JSON.stringify(this.metadata));
  	    // dont get data, if folder is not visible
  	   
  	    if ( ! this.data || ! this.visible)
  	    {
  	    	this.linkData = linkData;
  	        return;
  	    }
  	        
  	    // console.log( JSON.stringify(linkData));
  	    // console.log( "getData() " + JSON.stringify(this.definition));
  	    // console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+linkData.entity+"/all/true/0);
  	    
  	    //console.log(JSON.stringify(this.parentMeta));
    	

    	//console.log( "GSELECT "+key+"/"+this.loadedData);
        //console.log( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+entity+"/"+id+"/"+linkData.entity+"/options/true/0");
        
        //let url = fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+entity+"/"+column+"/"+id+"/"+linkData.name+"/options/true/0"; 
    	//let check = column;
    	HTTP.post( fwAPI+"/getEntityAsOptions/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+entity+"/"+column+"/"+id+"/"+linkData.name+"/true/0", JSON.stringify(this.data)) 
            .then( response => 
            { 
                  try{         
                       
                        this.loadedData = key;
                        this.selectData = response.data.data;
                        //for ( let i in this.selectData )
                        //{
                        //	this.selectData[i].label = this.selectData[i].id + this.selectData[i].label;
                        //}
                        //alert( "GSELECT["+link+"]> "+this.selectData.length);
                        let myId = parseInt(this.value);
                        this.selectedValue = this.selectData.find( p => p.id === myId);
                        //if ( myId === 1491 )
                        //{
                        //	alert( column + "/" + check );
                        //	alert(column+"/"+linkData.name+"/"+this.selectData.find( p => p.id === myId) + "/"+JSON.stringify(this.selectData));
                        //	alert( url);
                        //}
                        if ( ! this.selectedValue )
                        {
                            this.selectedValue  = [];
                        }
                        this.linkData = linkData;
                        this.setSelected();
                        //console.log(JSON.stringify(this.selectData));
                       } catch (e) { console.log( e ) }
                    }).catch(e => {
                           //alert( e.response.status + " " + JSON.stringify(e.response.data) )
                          });
        },
        getValue()
        {
           //alert( this.value);
        },
       
  },
  computed: {
  	// watcher() { return this.id+this.visible; } 
  },
  watch: {
      id: function()  { this.loadedData = ""; this.getData() },
      value: function() 
      	{
      	    //console.log("GSELECT:: VAL> " + this.value);
      		this.getValue()
      	},
      time: function()  { this.loadedData = ""; this.getData() },
      metadata: function()  { this.getData() },
      visible: function()  { this.getData() },
      definition: function() { this.getData() },
      selectedValue: function() 
      { 
          if (this.selectedValue && this.selectedValue.id && this.selectedValue.id > 0 ) 
          {
            //console.log( "input> " +this.selectedValue.id);
            this.$emit("input", this.selectedValue.id );
        }
        else
        {
            this.$emit("input", null );
        }
      }
  },
  updated() {
    //console.log( "GSELECT:visible> " + JSON.stringify(this.visible));
    this.loadedData = "";
  },
  created() {
	initAppMode();
    this.getData()
  }
 }
</script>
<style scss scoped>
.inputWrapper {
	width: 100% !important;
	display: inline-block;
	padding: 0;
	margin:0;
    border: .5pt solid #ccc;
    border-radius: 6px;
}
.input80 {
	width: 90% !important;
	display: inline-block;
	padding: 0;
	margin:0;
}

.input100 {
	width: 100% !important;
	display: inline-block;
	font-size: 8pt;
	padding: 0;
	margin:0;
}
.input20 {
	width: 10% !important;
	display: inline-block;
	text-align: right;
	vertical-align: top;
	padding: 0;
	margin:0;
}
.btn {
	height: 2.2em !important;
	padding-left: 0.5em;
	padding-right: 0.5em;
}
label, span{
        display: block;
    }
</style>