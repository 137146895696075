<template>
 <div>
    <vue-modal-2 :modalSize="'lg'" :noFooter=true :noHeader=true ref="xxx" name="xxx" style='z-index: 999; '>
       <div :style="getAppStyle()+'border: 2pt #ccc inset; height: calc(40vH) !important'">
       <div :style="getAppStyle()" class='appHead'>
             <div :style="'display: inline-block; float:left; width: 100%; color: '+getFGColor('#44c','#aaf')+'; font-weight: bold; padding-right: 5pt; font-size: 10pt;'">
              <div :style="'float:left; padding-left: 5pt; padding-top: 4pt; background-color: transparent !important;'" >
              Phases 
              </div>
              <div :style="'float:right; padding-right: 2pt; background-color: transparent !important;'" >
                  <WindowCloseBN @click="close"/>
		      </div>
            </div>
       </div> <!-- appHead -->
       <div style='display: block; float: left; border-bottom: 1pt solid grey; width: 100% !important;'>

		
      </div>
      <div :style="getAppStyle()+'display: block; float: left; width: 100% !important; overflow-y: auto;'">
       
        <div v-for="(phase,idx) in data" :key="'ph'+idx" style='border-bottom: 1px solid #ccc;'
             	@dragstart="startDragColumn($event, phase, idx)" draggable
	         	@drop="onDropColumn($event, idx)"
	         	@dragover.prevent
			 	@dragenter.prevent>
            {{phase.pos}}
            <div class="valXL">
        	<vSelect :appendToBody="true" placeholder='choose solver' 
        	       :options="solver"  
					v-model="phase.name">
			</vSelect>
			</div>
			
			<div class="val" title='time total for this phase'><InputText title='time total for this phase' @enter="saveAndClose" v-model="phase.timeTotal"/></div>
			<div class="val" title='time for unimprovements in this phase'><InputText @enter="saveAndClose" v-model="phase.timeUnimproved"/></div>
			<div class="valXS"><BUTTON @click="deleteRow(idx)"><mdicon size='16'  name='delete'/></BUTTON></div>
			
        </div>
       
        <div style='position: absolute; bottom: 0; right: 0; float: right; padding-bottom: 5pt;'>
        <div style='float: right; padding-right: 10pt;'>
        
        <button class="button" :style="getAppStyle()" type="button" @click="close">CLOSE</button>
        <button class="button" :style="getAppStyle()" type="button" @click="add">ADD</button>
        <button class="button" :style="getAppStyle()" type="button" @click="saveIt">SAVE</button>
        </div>
        </div>
        
      </div>
    </div>
   </vue-modal-2>
 </div>
</template>
<script>
import {HTTP, WSLURL, APIURL, APIURLB, APIURLA, port_LB, myLocale, showError, getServerInfo, xApi} from '@/variables.js';
import { getAppStyle, initAppMode, getBGStyle2, getFGColor } from '@/AppStyle.js';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
import InputTime from '@/components/inputElements/InputTime2';
import InputInt from '@/components/inputElements/InputInt';
import InputText from '@/components/inputElements/InputTextDT';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
export default {
  props: {
    generalInfo: { type: String },
  },
  data () {
    return {
    	showMe: false,
    	data: null,
    	solver: null,
    	currency: {},
    	rateCardsOption: [],
    	selectedRC: {},
    	getAppStyle,
    	getFGColor,
    	getBGStyle2,
    	myLocale
    }
  },
  components : {
	  WindowCloseBN, InputText,
	  //InputTime, InputText, InputInt, 
	  vSelect
  },
  computed: 
  {
    
  },
  created() 
  {
  		initAppMode();
  		this.$vm2.open('xxx');
  		 this.$nextTick(function () {
  		this.$vm2.close('xxx');
  		 });
  },
  
  methods: 
  {
	  open( line, name, solverList ) {
      	this.showMe = true;
      	let phaseDef = line[ name];
      	if ( !phaseDef )
      	{
      		line[ name] = { phases: []};
      		phaseDef = line[ name];
      	}
      	if ( !phaseDef.phases)
      	{
      		phaseDef.phases = [];
      		this.data = phaseDef.phases;
          	this.add();
      	}
      	else
      	{
      		this.data = phaseDef.phases;
      	}
      	
      	this.solver = solverList.filter( p=>p.label !== 'DEFAULT').map( p=>p.label);
      	this.$vm2.open('xxx');
 		 this.$nextTick(function () {
 		    this.$vm2.close('xxx');
 		    this.$vm2.open('xxx');
 		 });
      	
      	
      },
      startDragColumn (evt, col, idx) {
          evt.dataTransfer.dropEffect = 'move'
          evt.dataTransfer.effectAllowed = 'move'
          evt.dataTransfer.setData('rowIdx', idx)
    	},
    	onDropColumn (evt, target) {
    		
    		const idx = evt.dataTransfer.getData('rowIdx');
    		let source = this.data[ idx]; 
    		this.data.splice( idx, 1);
			this.data.splice( target, 0, source);
			for ( let i in this.data)
			{
				this.data[i].pos = parseInt(i)+1;
			}
         	
    	},
      saveAndClose()
      {
    		this.saveIt();
    		this.close();
      },
      saveIt()
      {
    	  this.$emit('changed', this.data);
    	  this.$emit('value', this.data);
      },
      add()
      {
    	  let pt = 'PT10S';
    	  let pi = 'PT2S';
    	  if ( this.data.length )
    	  {
    		  let last = this.data[ this.data.length-1];
    		  pt = last.timeTotal;
    		  pi = last.timeUnimproved;
    	  }
    	  this.data.push({pos: this.data.length+1, name: 'HILL_CLIMBING', timeTotal: pt, timeUnimproved: pi})
      },
      deleteRow(idx)
      {
    	  this.data.splice( idx, 1);
    	  for ( let i in this.data)
			{
				this.data[i].pos = parseInt(i)+1;
			}
      },
	  close() {
    	  this.$vm2.close('xxx');
      	  //this.$emit("changed", this.data, this.gridAds)
      },
      
  },
  watch: 
  {
       
  }
}
</script>
<style lang="scss" scoped>
.appHead {
	height: 22pt;
}

.tooltipClass:hover {
  background: #CCC;
}
.lb {
 display: inline-flex;
 width: 30%;
 padding-left: 9pt;
 font-size: 10pt;
 font-weight: bold; 
 margin-bottom: 3pt;
}
.val {
 display: inline-flex;
 width: 20%;
 text-align: left;
 padding-left: 5pt;
 font-size: 10pt; 
}
.valXL {
 display: inline-flex;
 width: 50%;
 text-align: left;
 padding-left: 5pt;
 font-size: 10pt; 
}
.valXS {
 display: inline-flex;
 width: 5%;
 text-align: left;
 padding-left: 5pt;
 font-size: 10pt; 
}
</style>