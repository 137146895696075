<template>
 <div>
    <XModal v-show="showMe" name="helpView" :shiftX="0.05" :shiftY="0.05" :reset="true" :adaptive="true" :resizable="true" :width="width+'%'" :height="height+'%'" :draggable="true" >
       <div :style="getAppStyle()+'border: 2pt #ccc inset; height: calc(40vH) !important'">
       <div :style="getAppStyle()" class='appHead'>
             <div :style="'display: inline-block; font-size: 12pt; float:left; width: 100%; color: '+getFGColor('#44c','#aaf')+'; font-weight: bold; padding-right: 5pt;'">
              <div :style="'float:left; padding-left: 5pt; padding-top: 4pt; background-color: transparent !important;'" >
              HELP {{title}}
              </div>
              <div :style="'float:right; padding-right: 2pt; background-color: transparent !important;'" >
                  <WindowCloseBN @click="close"/>
		      </div>
            </div>
       </div> <!-- appHead -->
       <div style='display: block; float: left; border-bottom: 1pt solid grey; width: 100% !important;'>

		
      </div>
      <div :style="getAppStyle()+'display: block; padding-left: 5pt; float: left; height: 100&; width: 100% !important; overflow-y: auto;'">
        <br/>
        <div v-for="(line,idx) in text" :key="'h'+idx">
        <div v-if="line.startsWith('<b>')" :style="'font-weight: bold; color: '+getFGColor('#44c','#aaf')">{{line.substring(3)}}</div>
        <li v-else>{{line}}</li>
        	
       	</div>
       
        <div style='position: absolute; bottom: 0; right: 0; float: right; padding-bottom: 5pt;'>
        <div style='float: right; padding-right: 10pt;'>
        
        <button class="button" :style="getAppStyle()" type="button" @click="close">CLOSE</button>
        </div>
        </div>
        
      </div>
    </div>
   </XModal>
 </div>
</template>
<script>
import {HTTP, WSLURL, APIURL, APIURLB, APIURLA, port_LB, myLocale, showError, getServerInfo, xApi} from '@/variables.js';
import { getAppStyle, initAppMode, getBGStyle2, getFGColor } from '@/AppStyle.js';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
import InputTime from '@/components/inputElements/InputTime2';
import InputInt from '@/components/inputElements/InputInt';
import InputText from '@/components/inputElements/InputTextDT';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
export default {
  props: {
    generalInfo: { type: String },
    height: {
        type: Number,
        default: 40
      },
      width: {
          type: Number,
          default: 50
        }
  },
  data () {
    return {
    	showMe: false,
    	text: [],
    	title: "",
    	getAppStyle,
    	getFGColor,
    	getBGStyle2,
    	myLocale
    }
  },
  components : {
	  WindowCloseBN
  },
  computed: 
  {
    
  },
  created() 
  {
  		initAppMode();
  		
  },
  
  methods: 
  {
	  open( title, text ) {
      	this.showMe = true;
      	this.title=title;
      	this.text = text;
      	//this.$vm2.open('helpView');
      	this.$modal.show("helpView");
      	
      	
      },
      startDragColumn (evt, col, idx) {
          evt.dataTransfer.dropEffect = 'move'
          evt.dataTransfer.effectAllowed = 'move'
          evt.dataTransfer.setData('rowIdx', idx)
    	},
    	onDropColumn (evt, target) {
    		
    		const idx = evt.dataTransfer.getData('rowIdx');
    		let source = this.data[ idx]; 
    		this.data.splice( idx, 1);
			this.data.splice( target, 0, source);
			for ( let i in this.data)
			{
				this.data[i].pos = parseInt(i)+1;
			}
         	
    	},
      saveAndClose()
      {
    		this.saveIt();
    		this.close();
      },
      saveIt()
      {
    	  this.$emit('changed', this.data);
    	  this.$emit('value', this.data);
      },
      add()
      {
    	  let pt = 'PT10S';
    	  let pi = 'PT2S';
    	  if ( this.data.length )
    	  {
    		  let last = this.data[ this.data.length-1];
    		  pt = last.timeTotal;
    		  pi = last.timeUnimproved;
    	  }
    	  this.data.push({pos: this.data.length+1, name: 'HILL_CLIMBING', timeTotal: pt, timeUnimproved: pi})
      },
      deleteRow(idx)
      {
    	  this.data.splice( idx, 1);
    	  for ( let i in this.data)
			{
				this.data[i].pos = parseInt(i)+1;
			}
      },
	  close() {
    	  this.showMe = false;
    	  this.$modal.hide("helpView");
      	  //this.$emit("changed", this.data, this.gridAds)
      },
      
  },
  watch: 
  {
       
  }
}
</script>
<style lang="scss" scoped>
.appHead {
	height: 22pt;
}

.tooltipClass:hover {
  background: #CCC;
}
.lb {
 display: inline-flex;
 width: 30%;
 padding-left: 9pt;
 font-size: 10pt;
 font-weight: bold; 
 margin-bottom: 3pt;
}
.val {
 display: inline-flex;
 width: 20%;
 text-align: left;
 padding-left: 5pt;
 font-size: 10pt; 
}
.valXL {
 display: inline-flex;
 width: 50%;
 text-align: left;
 padding-left: 5pt;
 font-size: 10pt; 
}
.valXS {
 display: inline-flex;
 width: 5%;
 text-align: left;
 padding-left: 5pt;
 font-size: 10pt; 
}
</style>